<template>
  <el-card class="main-card">
    <!-- 标题 -->
    <div class="title">{{ this.$route.name }}</div>
    <div class="operation-container">
      <el-button
          type="danger"
          size="small"
          icon="el-icon-deleteItem"
          :disabled="this.logIdList.length == 0"
          @click="isDelete = true"
      >
        批量删除
      </el-button>
      <!-- 数据筛选 -->
      <div style="margin-left:auto">
        <el-input
            v-model="keywords"
            prefix-icon="el-icon-search"
            size="small"
            placeholder="请输入模块名或描述"
            style="width:200px"
            @keyup.enter.native="listLogs"
        />
        <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            style="margin-left:1rem"
            @click="listLogs"
        >
          搜索
        </el-button>
      </div>
    </div>
    <!-- 权限列表 -->
    <el-table
        @selection-change="selectionChange"
        v-loading="loading"
        :data="logList"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column
          prop="uuid"
          label="访客标识码"
          align="center"
          width="120"
      />
      <el-table-column prop="uri" label="请求接口" align="center" />
      <el-table-column
          prop="method"
          label="请求方式"
          align="center"
          width="100"
      >
        <template slot-scope="scope" v-if="scope.row.method">
          <el-tag :type="tagType(scope.row.method)">
            {{ scope.row.method }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="param"
          label="请求参数"
          align="center"
          width="130"
      />
      <el-table-column
          prop="behavior"
          label="访问行为"
          align="center"
          width="130"
      />
      <el-table-column
          prop="ip"
          label="登录ip"
          align="center"
          width="130"
      />
      <el-table-column
          prop="ipSource"
          label="登录地址"
          align="center"
          width="150"
      />
      <el-table-column
          prop="createTime"
          label="操作时间"
          align="center"
          width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" style="margin-right:5px" />
          {{ scope.row.createTime }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-popconfirm
              title="确定删除吗？"
              style="margin-left:10px"
              @onConfirm="deleteLog(scope.row.id)"
          >
            <el-button size="mini" type="text" slot="reference">
              <i class="el-icon-delete" /> 删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
        class="pagination-container"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        :page-sizes="[10, 20]"
        layout="total, sizes, prev, pager, next, jumper"
    />
    <!-- 批量删除对话框 -->
    <el-dialog :visible.sync="isDelete" width="30%">
      <div class="dialog-title-container" slot="title">
        <i class="el-icon-warning" style="color:#ff9900" />提示
      </div>
      <div style="font-size:1rem">是否删除选中项？</div>
      <div slot="footer">
        <el-button @click="isDelete = false">取 消</el-button>
        <el-button type="primary" @click="deleteLog(null)">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "VisitLog",
  created() {
    this.listLogs();
  },
  data() {
    return {
      loading: true,
      logList: [],
      logIdList: [],
      keywords: "",
      currentPage: 1,
      pageSize: 10,
      total: 0,
      isDelete: false,
      optLog: {}
    };
  },
  methods: {
    selectionChange(logList) {
      this.logIdList = [];
      logList.forEach(item => {
        this.logIdList.push(item.id);
      });
    },
    sizeChange(size) {
      this.size = size;
      this.listLogs();
    },
    currentChange(currentPage) {
      this.currentPage = currentPage;
      this.listLogs();
    },
    listLogs() {
      this.axios
          .get("/api/admin/operation/visitLog", {
            params: {
              currentPage: this.currentPage,
              pageSize: this.pageSize,
              keywords: this.keywords
            }
          })
          .then(({ data }) => {
            this.logList = data.data.records;
            this.total = data.data.total;
            this.loading = false;
          });
    },
    deleteLog(id) {
      var param = {};
      if (id != null) {
        param = { data: [id] };
      } else {
        param = { data: this.logIdList };
      }
      this.axios.delete("/api/admin/operation/visitLog", param).then(({ data }) => {
        if (data.flag) {
          this.$notify.success({
            title: "成功",
            message: data.message
          });
          this.listLogs();
        } else {
          this.$notify.error({
            title: "失败",
            message: data.message
          });
        }
        this.isDelete = false;
      });
    }
  },
  computed: {
    tagType() {
      return function(type) {
        switch (type) {
          case "GET":
            return "";
          case "POST":
            return "success";
          case "PUT":
            return "warning";
          case "DELETE":
            return "danger";
        }
      };
    }
  }
}
</script>

<style scoped>
label {
  font-weight: bold !important;
}
</style>