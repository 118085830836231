<template>
  <el-card class="main-card">
    <!-- 标题 -->
    <div class="title">{{ this.$route.name }}</div>
    <div class="operation-container">
      <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="openModel(null)"
      >
        新增菜单
      </el-button>
      <!-- 数据筛选 -->
      <div style="margin-left:auto">
        <el-input
            v-model="keywords"
            prefix-icon="el-icon-search"
            size="small"
            placeholder="请输入菜单名"
            style="width:200px"
        />
        <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            style="margin-left:1rem"
        >
          搜索
        </el-button>
      </div>
    </div>
    <!-- 权限列表 -->
    <el-table
        v-loading="loading"
        :data="menuList"
        row-key="id"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="name" label="菜单名称" width="120" />
      <el-table-column prop="icon" align="center" label="图标" width="100">
        <template slot-scope="scope">
          <i :class="'iconfont ' + scope.row.icon" />
        </template>
      </el-table-column>
      <el-table-column
          prop="orderNum"
          align="center"
          label="排序"
          width="100"
      />
      <el-table-column prop="path" label="访问路径" />
      <el-table-column prop="component" label="组件路径" />
      <el-table-column prop="isDisable" label="禁用" align="center" width="80">
        <template slot-scope="scope">
          <el-switch
              v-model="scope.row.isDisable"
              active-color="#13ce66"
              inactive-color="#F4F4F5"
              :active-value="1"
              :inactive-value="0"
              @change="changeDisable(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="isHidden" label="隐藏" align="center" width="80">
        <template slot-scope="scope">
          <el-switch
              v-model="scope.row.isHidden"
              active-color="#13ce66"
              inactive-color="#F4F4F5"
              :active-value="1"
              :inactive-value="0"
              @change="changeDisable(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          width="150"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" style="margin-right:5px" />
          {{ scope.row.createTime | date }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button
              type="text"
              size="mini"
              @click="openModel(scope.row)"
              v-if="scope.row.children"
          >
            <i class="el-icon-plus" /> 新增
          </el-button>
          <el-button type="text" size="mini" @click="openEditModel(scope.row)">
            <i class="el-icon-edit" /> 修改
          </el-button>
          <el-popconfirm
              title="确定删除吗？"
              style="margin-left:10px"
              @onConfirm="deleteLink(scope.row.id)"
          >
            <el-button size="mini" type="text" slot="reference">
              <i class="el-icon-delete" /> 删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增模态框 -->
    <el-dialog :visible.sync="addMenu" title="新增菜单" width="30%" top="12vh">
      <el-form label-width="80px" size="medium" :model="addForm">
        <el-form-item v-if="isShow" label="菜单类型">
          <el-radio-group v-model="isCatalog">
            <el-radio :label="true">目录</el-radio>
            <el-radio :label="false">一级菜单</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="菜单名称">
          <el-input v-model="addForm.name" style="width:220px" />
        </el-form-item>
        <el-form-item label="菜单图标">
          <div @click="showIcon = true" >
            <el-input
                :prefix-icon="'iconfont ' + icon"
                v-model="addForm.icon"
                style="width:220px"
            />
          </div>
          <div class="menu-container" v-show="showIcon">
            <div
                v-for="(item, index) of iconList"
                :key="index"
                @click="checkIcon(item)"
            >
              <i :class="'iconfont ' + item" /> {{ item }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="组件路径" v-show="!isCatalog">
          <el-input v-model="addForm.component" style="width:220px" />
        </el-form-item>
        <el-form-item label="路由地址">
          <el-input v-model="addForm.path" style="width:220px" />
        </el-form-item>
        <el-form-item label="显示排序">
          <el-input-number
              v-model="addForm.orderNum"
              controls-position="right"
              @change="handleChange"
              :min="1"
              :max="10"
          />
        </el-form-item>
        <el-form-item label="显示状态">
          <el-radio-group v-model="addForm.isHidden">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="addMenu = false">取 消</el-button>
        <el-button type="primary">
          确 定
        </el-button>
      </div>
    </el-dialog>

    <!-- 编辑模态框 -->
    <el-dialog :visible.sync="editMenu" title="修改菜单" width="30%" top="12vh">
      <el-form label-width="80px" size="medium" :model="editForm">
        <el-form-item label="菜单名称">
          <el-input v-model="editForm.name" style="width:220px" />
        </el-form-item>
        <el-form-item label="菜单图标">
          <div @click="showIcon = true" v-close>
            <el-input
                :prefix-icon="'iconfont ' + editForm.icon"
                v-model="editForm.icon"
                style="width:220px"
            />
          </div>

          <div class="menu-container" v-show="showIcon">
            <div
                v-for="(item, index) of iconList"
                :key="index"
                @click="checkIcon(item)"
            >
              <i :class="'iconfont ' + item" /> {{ item }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="组件路径" v-show="!isCatalog">
          <el-input v-model="editForm.component" style="width:220px" />
        </el-form-item>
        <el-form-item label="路由地址">
          <el-input v-model="editForm.path" style="width:220px" />
        </el-form-item>
        <el-form-item label="显示排序">
          <el-input-number
              v-model="editForm.orderNum"
              controls-position="right"
              @change="handleChange"
              :min="1"
              :max="10"
          />
        </el-form-item>
        <el-form-item label="显示状态">
          <el-radio-group v-model="editForm.isHidden">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="editMenu = false">取 消</el-button>
        <el-button type="primary">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  created() {
    this.listMenus();
  },
  data() {
    return {
      keywords: "",
      loading: true,
      addMenu: false,
      editMenu: false,
      showIcon: false,
      isCatalog: true,
      isShow: true,
      menuList: [],
      addForm: {},
      editForm: {},
      menuForm: {},
      icon: "",
      iconList: [
       "el-icon-myshouye",
        "el-icon-myfabiaowenzhang",
        "el-icon-myyonghuliebiao",
        "el-icon-myliuyan",
        "el-icon-myxiaoxi",
        "el-icon-myfangwenliang",
        "el-icon-myicontag",
        "el-icon-mydashujukeshihuaico-",
        "el-icon-mywenzhang-copy",
        "el-icon-myguanyuwo"
      ]
    };
  },
  directives: {
    close: {
      inserted(el, binding, vnode) {
        window.onclick = function(e) {
          if (!el.contains(e.target)) {
            vnode.context.showIcon = false;
          }
        };
      }
    },
  },
  methods: {
    listMenus() {
      this.axios.get("/api/menu/menus").then(({ data }) => {
        this.menuList = data.data;
        this.loading = false;
      });
    },
    openModel(menu) {
      console.log(menu);
      if (menu===null){
        this.isShow = true;
        this.isCatalog = true;
      }else {
        this.isShow = false;
        this.isCatalog = false;
      }
      console.log(this.isCatalog);
      this.addMenu = true;
    },
    openEditModel(menu){
      console.log(menu);
      this.editForm=menu;
      this.icon = menu.icon;
      this.editMenu=true;
    },
    checkIcon(icon) {
      this.editForm.icon = icon;
      this.menuForm.icon = icon;
      this.showIcon = false;
    },
    handleChange(){

    },
    deleteLink(id){
      this.axios.delete("/api/menu/deleteMenu"+id).then(
          (({ data }) => {
            if (data.flag) {
              this.$notify.success({
                title: "成功",
                message: data.message
              });
              this.listUsers();
            } else {
              this.$notify.error({
                title: "失败",
                message: data.message
              });
            }
          }
      )
      )
    }

  }
};
</script>

<style scoped>
.menu-container {
  position: absolute;
  width: 50%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e6ebf5;
  padding: 12px;
  z-index: 2000;
  color: #606266;
  text-align: justify;
  font-size: 14px;
  -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  word-break: break-all;
  overflow-y: auto;
  height: 200px;
  display: inline-block;
}
.menu-container div {
  cursor: pointer;
}
</style>
